var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex h-100",style:(_vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.STUDENT ||
      _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.SCHOOL
        ? 'min-height: calc(100vh - 350px) !important'
        : '')},[_c('router-view',{attrs:{"layout-props":_vm.layoutProps}}),(
        (_vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.STUDENT || _vm.isRoutePublicSchlLanding) && _vm.isMobileScreen
      )?_c('swipe-bottom-navigation',{key:_vm.stdNavKey,attrs:{"options":_vm.isRoutePublicSchlLanding ? _vm.publicRouteOptions : _vm.studentRouteOptions,"swiper-color":"var(--primary-color)","background-color":"#FFFFFF","icon-color":_vm.get(_vm.getLoggedInUser, 'linked_entity.dark_color') || _vm.dark || '#272c33'},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
      var props = ref.props;
return [_c('md-icon',{style:({ color: ("" + (_vm.get(_vm.getLoggedInUser, 'linked_entity.dark_color','#272c33'))) || _vm.dark  })},[_vm._v(_vm._s(props.icon))])]}},{key:"title",fn:function(ref){
      var props = ref.props;
return [_c('b',[_vm._v(_vm._s(props.title))])]}}],null,false,3984328650),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }